import * as Pinia from 'pinia';

// STORE DEFINITIONS -------------------
/**
 * This is very similar to Student language, but has no extra controls to update etc.
 * It basically relies on student stuff, or defaults to "en" for safety.
 * Core itself does not provide language switching capabilites.
 * @exports Core_Store_Langugage
 * @namespace Core_Store_Langugage
 */

export const useLanguageStore = Pinia.defineStore({
	id: 'CoreLang',
	state: () => {
		return {
			string: {}
		};
	},

	actions: {
		/**
		 * Update existing language strings with new one from the Language Pack (the automated process builidng all *.lang files)
		 * @memberof Core_Store_Langugage
		 * @param {string} langCode new language to be loaded
		 */
		async update(langCode) {
			const newLang = langCode || 'en';
			app.log('CORE', 'Language pack:', newLang);

			// load correct langugage
			await import(`../languages/index.${newLang}.built.js`)
				.then((response) => {
					successResponse(this, response);
				})
				.catch(() => {
					this.update('en'); // falback to en
				});

			/**
			 * Abstraction of the success work to be done after importing a file.
			 * @param {object} state pass-in state
			 * @param {object} response response from import
			 */
			function successResponse(state, response) {
				state.string = response.default; // this is needed as it's deep object ($patch won't work correctly with it)
			}
		}
	}
});
