<template>
	<div
		:id="'wysiwyg-' + state.id"
		class="BpForms BpWysiwyg"
		:class="stateComputed.currentClasses"
		:data-testid="id + '-input'">
		<div>
			<label
				v-if="state.label"
				class="BpForms--label"
				:for="state.id">
				{{ state.label }}
				<span
					v-if="state.tooltip"
					class="Tooltip Tooltip-bottom"
					:data-tooltip="state.tooltip"
					tabindex="0"
					data-testid="blueprint-input-tooltip"
					><span class="Tooltip-icon BpForms--tooltip"></span
				></span>
				<span
					class="BpForms--optional"
					data-testid="forms-optional-label">
					{{ language.string.cForms.optional }}
				</span>
			</label>

			<div
				:id="'wysiwygEditor-' + randomId"
				ref="qlContainer"
				class="BpWysiwyg--container StandardHtml"></div>

			<img
				v-if="aiHelperEnabled"
				src="@School/assets/ai-icon.png?url"
				alt="AI Creator Start"
				class="BpWysiwyg--aiHelper"
				@click="startAiHelper()" />

			<transition name="slide-down">
				<div
					v-if="internal.currentMsg"
					class="BpForms--msg"
					data-testid="blueprint-input-msg"
					:data-msgtype="internal.currentMsgType">
					{{ internal.currentMsg }}
				</div>
			</transition>

			<!-- MEDIA UPLOADER POPUP  -->
			<blueprint-popup
				ref="popupMediaUploader"
				:header="language.string.cWysiwyg.uploadTitle">
				<template #default>
					<div class="row">
						<p>
							{{ language.string.cWysiwyg.uploadDesc }}
						</p>
						<blueprint-upload
							id="mediaUploaderFile"
							:ref="popupFields.file"
							v-model:value="popupData.file"
							:min="1"
							:max="1"
							:max-size="209715200"
							:file-types="
								popupData.type === 'image'
									? '.jpg,.jpeg,.png,.bmp'
									: popupData.type === 'video'
										? '.mp4,.avi,.mpg,.mov'
										: '.mp3,.wma,.wav'
							"
							required />

						<blueprint-input
							v-if="popupData.type === 'image'"
							id="mediaUploaderAlt"
							:ref="popupFields.alt"
							v-model:value="popupData.alt"
							:placeholder="language.string.cWysiwyg.descriptionPlaceholder" />

						<blueprint-checkbox
							v-if="popupData.type === 'image'"
							id="mediaUploaderFullScreen"
							:ref="popupFields.fullScreen"
							v-model:value="popupData.fullScreen"
							class="col-22"
							:label="language.string.cWysiwyg.fullscreenLabel" />

						<div class="Text-center">
							<button
								class="Btn"
								:class="popupData.saving && 'Btn-loading'"
								@click="!popupData.saving && addMedia()">
								{{ language.string.cWysiwyg.uploadBtn }}
							</button>
						</div>
					</div>
				</template>
			</blueprint-popup>

			<!-- BUTTON OPTIONS  -->
			<blueprint-popup
				ref="popupButtonOptions"
				:header="language.string.cWysiwyg.buttonTitle">
				<template #default>
					<div class="row">
						<blueprint-select
							id="buttonStyle"
							:ref="buttonFields.style"
							v-model:value="buttonData.style"
							class="col-12"
							:options="buttonStyles"
							:label="language.string.cWysiwyg.buttonStyleLabel"
							required />

						<blueprint-select
							id="buttonSize"
							:ref="buttonFields.size"
							v-model:value="buttonData.size"
							class="col-12"
							:options="buttonSizes"
							:label="language.string.cWysiwyg.buttonSizeLabel"
							required />

						<blueprint-input
							id="buttonText"
							:ref="buttonFields.text"
							v-model:value="buttonData.text"
							:label="language.string.cWysiwyg.buttonTextLabel"
							required />

						<blueprint-input
							id="buttonUrl"
							:ref="buttonFields.url"
							v-model:value="buttonData.url"
							:label="language.string.cWysiwyg.buttonLinkLabel"
							required />

						<div class="col-24 Space-top">
							<p>
								<span class="Text-bold"
									>{{ language.string.cWysiwyg.buttonExampleText }}
								</span>
							</p>
							<div
								:class="buttonData.style + ' ' + buttonData.size"
								:href="buttonData.url">
								{{ buttonData.text || language.string.cWysiwyg.noText }}
							</div>
						</div>

						<div class="Text-right">
							<button
								class="Btn Space-bottomNone"
								@click="addButton">
								{{ language.string.cWysiwyg.buttonInsert }}
							</button>
						</div>
					</div>
				</template>
			</blueprint-popup>

			<!-- PRODUCT WIDGET OPTIONS  -->
			<blueprint-popup
				ref="popupWidgetOptions"
				:header="language.string.cWysiwyg.widgetTitle">
				<template #default>
					<div class="row">
						<blueprint-select
							id="buttonStyle"
							:ref="productWidgetFields.type"
							v-model:value="productWidgetData.type"
							class="col-12"
							:options="productWidgetTypes"
							:label="language.string.cWysiwyg.widgetTypeLabel"
							required />
						<blueprint-input
							id="buttonStyle"
							:ref="productWidgetFields.tag"
							v-model:value="productWidgetData.tags"
							class="col-8"
							:label="language.string.cWysiwyg.widgetTagLabel" />
						<blueprint-input
							id="buttonStyle"
							:ref="productWidgetFields.items"
							v-model:value="productWidgetData.items"
							class="col-4"
							:label="language.string.cWysiwyg.widgetItemsLabel"
							required />

						<div class="Text-right">
							<button
								class="Btn Space-bottomNone"
								@click="addProductWidget">
								{{ language.string.cWysiwyg.widgetTagLabel }}
							</button>
						</div>
					</div>
				</template>
			</blueprint-popup>

			<ai-helper
				v-if="aiHelperEnabled"
				ref="aiHelper"
				:content="aiContent"
				project="product"
				@update="aiContentUpdated"></ai-helper>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';
	import * as Helpers from './helpers.js';
	import AiHelper from '@Core/components/AiHelper/index.vue';

	export default {
		name: 'BlueprintWysiwyg',

		components: { 'ai-helper': AiHelper },

		// ---------- PROPS ----------
		props: {
			/**
			 * property {string} id - unique id
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {string} id - unique id
			 */
			id: {
				type: String,
				required: true
			},

			/**
			 * property {string | number | null | undefined} [value] - bind value
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {string | number | null | undefined} [value] - bind value
			 */
			value: {
				type: [String, Number, null, undefined],
				required: false,
				default: undefined
			},

			/**
			 * property {string} uploadType - upload item's id
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {string} uploadType - upload item's id
			 */
			uploadType: {
				type: String,
				required: true
			},

			/**
			 * property {object} uploadData - extra object with data needed for to upload files
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {object} uploadData - extra object with data needed for to upload files
			 */
			uploadData: {
				type: Object,
				required: true
			},

			/**
			 * property {string} [label] - label, or title
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {string} [label] - label, or title
			 */
			label: {
				type: String,
				required: false,
				default: undefined
			},
			/**
			 * property {boolean} [disabled] - should this field be "disabled"
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {boolean} [disabled] - should this field be "disabled"
			 */
			disabled: {
				type: Boolean,
				required: false
			},
			/**
			 * property {string} [simpleVersion] - less cluttered version of the editor
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {string} [simpleVersion] - less cluttered version of the editor
			 */
			simpleVersion: {
				type: Boolean,
				required: false,
				default: false
			},
			/**
			 * property {string} [minHeight] - min height of the editor, if needed
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {string} [minHeight] - min height of the editor, if needed
			 */
			minHeight: {
				type: String,
				required: false,
				default: '100px'
			},
			/**
			 * property {string} [theme=snow] - theme to be used for the editor (snow | bubble)
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {string} [theme=snow] - theme to be used for the editor (snow | bubble)
			 */
			theme: {
				type: String,
				required: false,
				default: 'snow',
				validator: (value) => ['snow', 'bubble'].includes(value)
			},
			/**
			 * property {boolean} [required=false] - is this field required?
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {boolean} [required=false] - is this field required?
			 */
			required: {
				type: Boolean,
				required: false
			},
			/**
			 * property {string} [tooltip] - content to be shown as tooltip within label area
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {string} [tooltip] - content to be shown as tooltip within label area
			 */
			tooltip: {
				type: String,
				required: false,
				default: undefined
			},
			/**
			 * property {Function} [onFocus=noop] - function to be called when field is focused
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {Function} [onFocus=noop] - function to be called when field is focused
			 */
			onFocus: {
				type: Function,
				required: false,
				default: function () {}
			},
			/**
			 * property {Function} [onBlur=noop] - function to be called when blur event happens
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {Function} [onBlur=noop] - function to be called when blur event happens
			 */
			onBlur: {
				type: Function,
				required: false,
				default: function () {}
			},
			/**
			 * property {boolean} [showValidationText=true] - do we want validation text to be shown
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {boolean} [showValidationText=true] - do we want validation text to be shown
			 */
			showValidationText: {
				type: Boolean,
				required: false,
				default: true
			},

			/**
			 * property {boolean} [aiHelperEnabled=false] - should we show AI helper button
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {boolean} [aiHelperEnabled=false] - should we show AI helper button
			 */
			aiHelperEnabled: {
				type: Boolean,
				required: false,
				default: false
			}
		},

		//  ---------- EMITS ----------
		emits: ['update:value'],

		//  ---------- SETUP ----------
		setup(props, context) {
			const language = useLanguageStore();
			const inputRef = Core.Vue.ref(null);
			// reactive props, that's all
			const state = Core.Vue.computed(() => {
				return {
					...props // make all props reactive,
				};
			});

			const darkBackground = Core.Vue.ref(false);

			// internal values only (can be set from inside or outside)
			const internal = Core.Vue.reactive({
				currentMsg: state.value.msg,
				currentMsgType: state.value.msgType
			});

			// ----------------------- QUILL EDITOR CONFIG AND STUFF -----------------------
			let quill;
			let toolbarOptions;
			const qlContainer = Core.Vue.ref();
			const randomId = Core.Vue.ref(Core.Utils.makeid(5));

			// toolbar options
			const fontSizeArr = [
				'8px',
				'9px',
				'10px',
				'12px',
				'14px',
				'16px',
				'20px',
				'24px',
				'32px',
				'42px',
				'54px',
				'68px',
				'84px',
				'98px'
			];
			if (props.simpleVersion) {
				toolbarOptions = [
					[{ header: [1, 2, 3, 4, false] }],
					['bold', 'italic', 'underline', 'strike'],
					[{ color: [] }],
					[{ align: [] }, { indent: '-1' }, { indent: '+1' }],
					[{ list: 'ordered' }, { list: 'bullet' }, 'hr', 'spanblock'],
					['link'],
					['clean', 'darkLight'] // remove formatting button
				];
			} else {
				toolbarOptions = [
					[{ header: [1, 2, 3, 4, false] }, { size: fontSizeArr }],
					[
						'bold',
						'italic',
						'underline',
						'strike',
						{ script: 'sub' },
						{ script: 'super' }
					],
					[{ color: [] }, { background: [] }],
					[{ align: [] }, { indent: '-1' }, { indent: '+1' }],
					[
						{ list: 'ordered' },
						{ list: 'bullet' },
						'blockquote',
						'code-block',
						'hr',
						'spanblock'
					],
					['link', 'button', 'productWidget', 'image', 'video', 'audio'],
					[
						'tableAdd',
						'tableDelete',
						'tableRowAddBefore',
						'tableRowAddAfter',
						'tableRowDelete',
						'tableColumnAddBefore',
						'tableColumnAddAfter',
						'tableColumnDelete'
					],
					['clean', 'darkLight'] // remove formatting button,
				];
			}

			Core.Vue.onMounted(async () => {
				const { default: Quill } = await import('quill');
				const { default: MagicUrl } = await import('quill-magic-url');
				const { default: PasteSmart } = await import('quill-paste-smart');
				const { default: BlotFormatter2 } = await import(
					'@enzedonline/quill-blot-formatter2'
				);

				// get the right CSS theme
				if (props.theme === 'bubble') {
					await import('quill/dist/quill.bubble.css');
				} else {
					await import('quill/dist/quill.snow.css');
				}

				const BlockEmbed = Quill.import('blots/block/embed');
				const Inline = Quill.import('blots/inline');
				const Size = Quill.import('attributors/style/size');

				// DEFINE CUSTOM FORMATS
				// video blot
				class VideoBlot extends BlockEmbed {
					static blotName = 'video';
					static tagName = 'video';

					static create(value) {
						const node = super.create();

						// fetch signed URL asynchronously
						if (value['data-filepath'] && value['data-filepath'] !== '') {
							setTimeout(async () => {
								const signedUrl = await getSignedUrl(value['data-filepath']);
								node.setAttribute('src', signedUrl);
							}, 0);
						} else {
							node.setAttribute('src', value.src || '');
						}

						node.setAttribute('controls', 'true');
						node.setAttribute('data-filepath', value['data-filepath'] || '');
						node.setAttribute('data-thumbnail', value['data-thumbnail'] || '');
						node.setAttribute('width', value.width || '100%');
						node.setAttribute('height', value.height || '');
						node.setAttribute('style', value.style || '');
						return node;
					}

					static value(node) {
						return {
							'data-filepath': node.getAttribute('data-filepath'),
							'data-thumbnail': node.getAttribute('data-thumbnail'),
							width: node.getAttribute('width'),
							height: node.getAttribute('height'),
							style: node.getAttribute('style'),
							src: node.getAttribute('src')
						};
					}
				}

				// image blot
				class ImageBlot extends BlockEmbed {
					static blotName = 'image';
					static tagName = 'img';

					static create(value) {
						const node = super.create();

						// fetch signed URL asynchronously
						if (value['data-filepath'] && value['data-filepath'] !== '') {
							setTimeout(async () => {
								const signedUrl = await getSignedUrl(value['data-filepath']);
								node.setAttribute('src', signedUrl);
							}, 0);
						} else {
							node.setAttribute('src', value.src || '');
						}

						node.setAttribute('alt', value.alt || '');
						node.setAttribute('data-filepath', value['data-filepath'] || '');
						node.setAttribute('width', value.width || '');
						node.setAttribute('height', value.height || '');
						node.setAttribute('data-fullscreen', value['data-fullscreen'] || 'false');
						if (
							value['data-fullscreen'] === 'true' ||
							value['data-fullscreen'] === true
						) {
							node.setAttribute('class', 'StandardHtml--imagePopup');
						}
						return node;
					}

					static value(node) {
						return {
							alt: node.getAttribute('alt'),
							'data-filepath': node.getAttribute('data-filepath'),
							'data-fullscreen': node.getAttribute('data-fullscreen'),
							src: node.getAttribute('src'),
							width: node.getAttribute('width'),
							height: node.getAttribute('height')
						};
					}
				}

				// audio blot
				class AudioBlot extends BlockEmbed {
					static blotName = 'audio';
					static tagName = 'audio';

					static create(value) {
						const node = super.create();

						// fetch signed URL asynchronously
						if (value['data-filepath'] && value['data-filepath'] !== '') {
							setTimeout(async () => {
								const signedUrl = await getSignedUrl(value['data-filepath']);
								node.setAttribute('src', signedUrl);
							}, 0);
						} else {
							node.setAttribute('src', value.src || '');
						}

						node.setAttribute('controls', 'true');
						node.setAttribute('data-filepath', value['data-filepath'] || '');
						return node;
					}

					static value(node) {
						return {
							'data-filepath': node.getAttribute('data-filepath'),
							src: node.getAttribute('src')
						};
					}
				}

				// hr blot
				class Hr extends BlockEmbed {
					static blotName = 'hr';
					static tagName = 'hr';
					static create(value) {
						const node = super.create(value);
						return node;
					}
				}

				// spanblock (fancy breakline) blot
				class SpanBlock extends Inline {
					static blotName = 'spanblock';
					static tagName = 'spanblock';
					static create() {
						const node = super.create();
						node.setAttribute('class', 'ql-breakline clearfix');
						node.innerHTML = '&nbsp;';
						return node;
					}
				}

				// button blot
				class Button extends BlockEmbed {
					static blotName = 'button';
					static tagName = 'a';
					static className = 'Btn';
					static create(value) {
						const node = super.create();
						node.setAttribute('href', value['href']);
						node.setAttribute('class', value['class']);
						node.innerHTML = value.text;

						// add target="_blank" if needed
						if (value['href'] && value['href'].includes('http')) {
							node.setAttribute('target', '_blank');
						}

						return node;
					}

					static value(node) {
						return {
							href: node.getAttribute('href'),
							class: node.getAttribute('class'),
							text: node.innerHTML
						};
					}
				}

				// productWidget blot
				class ProductWidget extends BlockEmbed {
					static blotName = 'productWidget';
					static tagName = 'div';
					static className = 'StandardHtml--productWidget';
					static create(value) {
						const node = super.create();
						node.setAttribute('data-type', value['data-type']);
						node.setAttribute('data-tags', value['data-tags']);
						node.setAttribute('data-items', value['data-items']);
						node.innerHTML = `<div class="StandardHtml--productWidgetPlaceholder"><b>Product Widget</b> (will appear in live version if there is at least one product available)<br/>Type: ${value['data-type']}<br/>Tags: ${value['data-tags'] || value['data-tags'] === 'null' ? 'none' : value['data-tags']}<br/>Items: ${value['data-items']}</div>`;
						return node;
					}

					static value(node) {
						return {
							'data-type': node.getAttribute('data-type'),
							'data-tags': node.getAttribute('data-tags'),
							'data-items': node.getAttribute('data-items')
						};
					}
				}

				Size.whitelist = fontSizeArr;
				Quill.register(Size, true);

				// register modules
				Quill.register({
					'modules/magicUrl': MagicUrl,
					'modules/pasteSmart': PasteSmart,
					'modules/blotFormatter2': BlotFormatter2,
					'formats/video': VideoBlot,
					'formats/image': ImageBlot,
					'formats/audio': AudioBlot,
					'formats/hr': Hr,
					'formats/button': Button,
					'formats/productWidget': ProductWidget,
					'formats/spanblock': SpanBlock
				});

				// set up quill editor
				quill = new Quill(`#wysiwygEditor-${randomId.value}`, {
					theme: props.theme,
					readOnly: state.value.disabled,
					clipboard: {
						matchVisual: false
					},
					modules: {
						toolbar: {
							container: toolbarOptions
						},
						magicUrl: true,
						table: true,
						pasteSmart: true,
						blotFormatter2: {
							align: {
								allowAligning: true,
								alignments: ['left', 'center', 'right']
							},
							resize: {
								useRelativeSize: true,
								allowResizeModeChange: true,
								imageOversizeProtection: true
							},
							image: {
								registerImageTitleBlot: false,
								allowAltTitleEdit: false
							},
							video: {
								selector: 'video',
								registerCustomVideoBlot: false,
								registerBackspaceFix: true
							}
						}
					}
				});

				// kill bindings for ENTER as they prevent me from doing SHIFT ENTER (omg !! this is fucking stupid)
				// TODO this is likely to be added when I fix <br/> :D
				// const keyboard = quill.getModule('keyboard');
				// delete keyboard.bindings['Enter'];

				// overwrite default image and video handler
				if (!props.simpleVersion) {
					quill.getModule('toolbar').addHandler('image', function () {
						showMediaUploader('image');
					});
					quill.getModule('toolbar').addHandler('video', function () {
						showMediaUploader('video');
					});
					quill.getModule('toolbar').addHandler('audio', function () {
						showMediaUploader('audio');
					});
					quill.getModule('toolbar').addHandler('hr', function () {
						const cursor = quill.getSelection(true);
						quill.insertEmbed(cursor.index, 'hr', 'null');
					});
					quill.getModule('toolbar').addHandler('spanblock', function () {
						const cursor = quill.getSelection(true);
						quill.insertEmbed(cursor.index, 'spanblock', 'null');
					});
					quill.getModule('toolbar').addHandler('productWidget', function () {
						showWidgetOptions();
					});

					// table buttons
					const table = quill.getModule('table');
					document
						.querySelector(`#wysiwyg-${props.id} .ql-tableAdd`)
						.addEventListener('click', function () {
							table.insertTable(2, 2);
						});
					document
						.querySelector(`#wysiwyg-${props.id} .ql-tableRowAddBefore`)
						.addEventListener('click', function () {
							table.insertRowAbove();
						});
					document
						.querySelector(`#wysiwyg-${props.id} .ql-tableRowAddAfter`)
						.addEventListener('click', function () {
							table.insertRowBelow();
						});
					document
						.querySelector(`#wysiwyg-${props.id} .ql-tableColumnAddBefore`)
						.addEventListener('click', function () {
							table.insertColumnLeft();
						});
					document
						.querySelector(`#wysiwyg-${props.id} .ql-tableColumnAddAfter`)
						.addEventListener('click', function () {
							table.insertColumnRight();
						});
					document
						.querySelector(`#wysiwyg-${props.id} .ql-tableRowDelete`)
						.addEventListener('click', function () {
							table.deleteRow();
						});
					document
						.querySelector(`#wysiwyg-${props.id} .ql-tableColumnDelete`)
						.addEventListener('click', function () {
							table.deleteColumn();
						});
					document
						.querySelector(`#wysiwyg-${props.id} .ql-tableDelete`)
						.addEventListener('click', function () {
							table.deleteTable();
						});
				}

				quill.getModule('toolbar').addHandler('button', function () {
					showButtonOptions();
				});

				// handle background color switch
				const button = document.querySelector(`#wysiwyg-${props.id} .ql-darkLight`);
				button.addEventListener('click', () => {
					darkBackground.value = !darkBackground.value;
				});

				// inject initial value
				quill.clipboard.dangerouslyPasteHTML(0, state.value.value);

				// update value on change
				quill.on('text-change', () => {
					state.value.value = quill.root.innerHTML;
					context.emit('update:value', state.value.value);
				});
			});

			// our computed and transformed values to use as we need (from props)
			const stateComputed = Core.Vue.computed(() => {
				let finalClasses = Helpers.resolveClassNames(state.value, internal);
				if (darkBackground.value) {
					finalClasses += ' BpWysiwyg-dark';
				}

				return {
					currentClasses: finalClasses
				};
			});

			// allow update to internals from outside via props
			Core.Vue.watch(
				() => [props.msg, props.msgType],
				([msgNew, msgTypeNew]) => {
					internal.currentMsg = msgNew;
					internal.currentMsgType = msgTypeNew;
				}
			);

			// --------- TOOLBAR TOOLTIP FUNCTIONALITY ---------------
			const tooltipMap = language.string.cWysiwyg.tooltips;

			setTimeout(() => {
				const toolbar = document.querySelector(`#wysiwyg-${props.id} .ql-toolbar`);
				if (toolbar) {
					toolbar.querySelectorAll('.ql-formats button').forEach((item) => {
						addTooltip(item);
					});

					toolbar.querySelectorAll('.ql-formats span').forEach((item) => {
						addTooltip(item);
					});
				}
			}, 1000);

			/**
			 * Add tooltip to the toolbar buttons
			 * @param {object} item - toolbar button
			 */
			function addTooltip(item) {
				const tooltipClass = item.getAttribute('class');
				const tooltipValue = item.getAttribute('value');

				if (tooltipMap[tooltipClass] || tooltipMap[tooltipValue]) {
					item.setAttribute(
						'title',
						tooltipMap[tooltipClass] || tooltipMap[tooltipValue]
					);
				}
			}

			/* -------------------- MEDIA UPLOAD FUNCTIONALITY ---------------------- */
			const popupMediaUploader = Core.Vue.ref();
			const popupFields = {
				file: Core.Vue.ref(),
				alt: Core.Vue.ref()
			};
			const popupData = Core.Vue.reactive({
				type: null,
				cursor: null,
				file: null,
				alt: null,
				fullScreen: false,
				saving: false
			});

			/**
			 * Show media uploader popup
			 * @param {string} type - type of media to upload ('image' | 'video')
			 */
			function showMediaUploader(type) {
				popupData.cursor = quill.getSelection(true);
				popupData.type = type;
				popupMediaUploader.value.toggle();
			}

			/**
			 * Fetch signed URL from the safe location based on the "relative" path provided
			 * @async
			 * @param {string} filePath - Relative path of the file
			 * @returns {Promise<string>} - Signed URL
			 */
			async function getSignedUrl(filePath) {
				const response = await Core.Api.post('school/wysiwyg-signedUrl', { filePath });
				return response.body.message;
			}

			/**
			 * Add selected image to the editor at selected cursor position
			 */
			async function addMedia() {
				popupData.saving = true;

				if (Core.Utils.validateFields(popupFields)) {
					let filePath = '';
					let fileSrc = '';

					// upload image
					if (popupFields.file.value?.filelist.length) {
						try {
							const uploadStatus = await popupFields.file.value.upload(
								`${Core.Config.BACKEND_API}school/wysiwyg-upload`,
								{
									type: props.uploadType,
									data: JSON.stringify(props.uploadData)
								}
							);

							// get the right name with relative path in DATA-FILEPATH or directly SRC for non-secure images
							filePath =
								props.uploadType === 'lessons'
									? `products/${props.uploadData.productId}/${props.uploadData.lessonId}/${uploadStatus[0].name}`
									: filePath;
							filePath =
								props.uploadType === 'assignments'
									? `products/${props.uploadData.productId}/${props.uploadData.lessonId}/${props.uploadData.assignmentId}/${uploadStatus[0].name}`
									: filePath;
							fileSrc =
								props.uploadType === 'courses' ||
								props.uploadType === 'articles' ||
								props.uploadType === 'downloads'
									? `${Core.Config.BUCKET_SCHOOL}/${props.uploadData.schoolId}/products/${props.uploadData.productId}/content/${uploadStatus[0].name}`
									: fileSrc;
							fileSrc =
								props.uploadType === 'pages'
									? `${Core.Config.BUCKET_SCHOOL}/${props.uploadData.schoolId}/pages/${props.uploadData.pageId}/content/${uploadStatus[0].name}`
									: fileSrc;
						} catch (error) {
							Core.Event.trigger('NOTIFICATIONS', {
								type: 'danger',
								content: language.string.cWysiwyg.error,
								icon: 'iconFont-cross'
							});
							popupData.saving = false;
							popupMediaUploader.value.toggle();
							return;
						}

						// ADD IMAGE
						if (popupData.type === 'image') {
							quill.insertEmbed(popupData.cursor.index, 'image', {
								alt: popupData.alt,
								'data-fullscreen': popupData.fullScreen,
								'data-filepath': filePath,
								src: fileSrc
							});
						}

						// // ADD VIDEO
						if (popupData.type === 'video') {
							quill.insertEmbed(popupData.cursor.index, 'video', {
								'data-filepath': filePath,
								src: fileSrc
							});
						}

						// // ADD AUDIO
						if (popupData.type === 'audio') {
							quill.insertEmbed(popupData.cursor.index, 'audio', {
								'data-filepath': filePath,
								src: fileSrc
							});
						}
					}

					popupMediaUploader.value.toggle();

					// reset popupData
					popupData.type = null;
					popupData.cursor = null;
					popupData.file = null;
					popupData.alt = null;
					popupData.fullScreen = false;
				}

				popupData.saving = false;
			}

			/* -------------------- BUTTON FUNCTIONALITY ---------------------- */
			const popupButtonOptions = Core.Vue.ref();
			const buttonFields = {
				style: Core.Vue.ref(),
				url: Core.Vue.ref(),
				text: Core.Vue.ref(),
				size: Core.Vue.ref()
			};
			const buttonData = Core.Vue.reactive({
				style: 'Btn',
				url: null,
				text: null,
				size: ' '
			});
			const buttonStyles = [
				{ value: 'Btn', name: 'Default / Primary' },
				{ value: 'Btn Btn-secondary', name: 'Secondary' },
				{ value: 'Btn Btn-tertiary', name: 'Tertiary' },
				{ value: 'Btn Btn-warning', name: 'Warning' },
				{ value: 'Btn Btn-danger', name: 'Error' },
				{ value: 'Btn Btn-success', name: 'Success' },
				{ value: 'Btn Btn-info', name: 'Neutral' }
			];
			const buttonSizes = [
				{ value: 'Btn-tiny', name: 'Tiny' },
				{ value: 'Btn-small', name: 'Small' },
				{ value: ' ', name: 'Normal' }
			];

			/**
			 * Show button options popup
			 */
			function showButtonOptions() {
				popupButtonOptions.value.toggle();
			}

			/**
			 * Add button to the editor at selected cursor position
			 */
			function addButton() {
				if (Core.Utils.validateFields(buttonFields)) {
					quill.insertEmbed(quill.getSelection(true).index, 'button', {
						'data-url': buttonData.url,
						class: `${buttonData.style} ${buttonData.size}`,
						href: buttonData.url,
						text: buttonData.text
					});

					popupButtonOptions.value.toggle();

					// reset data for the popup
					buttonData.style = 'Btn';
					buttonData.url = null;
					buttonData.text = null;
					buttonData.size = ' ';

					return;
				}
			}

			/* -------------------- PRODUCT WIDGET FUNCTIONALITY ---------------------- */
			const popupWidgetOptions = Core.Vue.ref();
			const productWidgetFields = {
				type: Core.Vue.ref(),
				items: Core.Vue.ref(),
				tags: Core.Vue.ref()
			};
			const productWidgetData = Core.Vue.reactive({
				type: 'all',
				items: 3,
				tags: null
			});
			const productWidgetTypes = [
				{ value: 'all', name: 'All products' },
				{ value: 'courses', name: 'Courses' },
				{ value: 'downloads', name: 'Digital Downloads' },
				{ value: 'articles', name: 'Articles' }
			];

			/**
			 * Show widget options popiip
			 */
			function showWidgetOptions() {
				popupWidgetOptions.value.toggle();
			}

			/**
			 * Add product widget to the editor at selected cursor position
			 */
			function addProductWidget() {
				if (Core.Utils.validateFields(productWidgetFields)) {
					quill.insertEmbed(quill.getSelection(true).index, 'productWidget', {
						'data-type': productWidgetData.type,
						'data-tags': productWidgetData.tags,
						'data-items': productWidgetData.items
					});

					popupWidgetOptions.value.toggle();

					// reset data for the popup
					productWidgetData.type = 'all';
					productWidgetData.items = 3;
					productWidgetData.tags = null;

					return;
				}
			}

			/* -------------------- AI HELPER FUNCTIONALITY ---------------------- */
			const aiHelper = Core.Vue.ref();
			let aiCursor = 0;
			let aiLength = 0;
			let aiContent = '';
			/**
			 * Start AI helper
			 */
			function startAiHelper() {
				aiCursor = quill.getSelection(true).index;
				aiLength = quill.getSelection(true).length;

				// get content
				if (aiLength > 0) {
					aiContent = quill.getSemanticHTML(aiCursor, aiLength);
				} else {
					aiContent = quill.getSemanticHTML(0, quill.getLength());
				}

				aiHelper.value.open(aiContent);
			}

			/**
			 * Update content of the editor with AI helper's content
			 * @param {string} content from ai helper
			 */
			function aiContentUpdated(content) {
				// if selection made, replace it with new content
				if (aiLength > 0) {
					quill.deleteText(aiCursor, aiLength);
					quill.clipboard.dangerouslyPasteHTML(aiCursor, content);
				} else {
					// delete all content and replace with new
					quill.setText('');
					quill.clipboard.dangerouslyPasteHTML(0, content);
				}
			}

			/* -------------------- UPDATE & VALIDATE FUNCTIONALITY ---------------------- */
			/**
			 * updateValue the value from input, validate if needed
			 * @returns {boolean} false if validation failed
			 */
			function updateValue() {
				state.value.value = state.value.value.trim();
				context.emit('update:value', state.value.value);
				return true;
			}

			/**
			 * validate the field
			 * @returns {boolean} validation result
			 */
			function validate() {
				return Helpers.validators(state, internal, inputRef);
			}

			return {
				state,
				stateComputed,
				internal,
				darkBackground,
				language,
				inputRef,
				updateValue,
				validate,
				toolbarOptions,
				randomId,
				qlContainer,
				popupMediaUploader,
				popupData,
				popupFields,
				addMedia,
				popupButtonOptions,
				buttonStyles,
				buttonSizes,
				buttonFields,
				buttonData,
				addButton,
				showWidgetOptions,
				popupWidgetOptions,
				productWidgetFields,
				productWidgetData,
				productWidgetTypes,
				addProductWidget,
				aiHelper,
				aiContent,
				startAiHelper,
				aiContentUpdated
			};
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@import './index.scss';
	@import '@enzedonline/quill-blot-formatter2/dist/css/quill-blot-formatter2.css';

	@include block('BpWysiwyg') {
		@include element('input') {
			height: auto;
		}

		@include modifier('dark') {
			& .BpWysiwyg--container {
				background-color: var(--color-grey300) !important;
			}
		}

		// styles for buttons in the toolbar
		& .ql-toolbar {
			border-top-left-radius: var(--radius-big);
			border-top-right-radius: var(--radius-big);

			button:hover,
			.ql-picker:hover {
				background-color: var(--color-greyStart);
			}

			& .ql-hr:after {
				content: '⎯';
			}
			& .ql-spanblock:after {
				content: '␣';
			}
			& .ql-button:after {
				content: '⎄';
				top: -3px;
				font-size: 18px;
				position: relative;
			}
			& .ql-productWidget:after {
				content: '⎆';
				top: -3px;
				font-size: 22px;
				position: relative;
			}
			& .ql-audio:after {
				content: '🎵';
				font-size: 12px;
				position: relative;
				top: -3px;
			}
			& .ql-darkLight:after {
				font-family: 'iconFont' !important;
				content: $iconFont-switch;
				position: relative;
			}

			// table buttons styles
			& .ql-tableAdd:after {
				font-family: 'iconFont' !important;
				content: $iconFont-tableAdd;
				position: relative;
			}
			& .ql-tableDelete:after {
				font-family: 'iconFont' !important;
				content: $iconFont-tableDelete;
				font-size: 13px;
				position: relative;
			}
			& .ql-tableRowAddBefore:after {
				font-family: 'iconFont' !important;
				content: $iconFont-tableRowAddBefore;
				position: relative;
			}
			& .ql-tableRowAddAfter:after {
				font-family: 'iconFont' !important;
				content: $iconFont-tableRowAddAfter;
				position: relative;
			}
			& .ql-tableRowDelete:after {
				font-family: 'iconFont' !important;
				content: $iconFont-tableRowDelete;
				position: relative;
			}
			& .ql-tableColumnAddBefore:after {
				font-family: 'iconFont' !important;
				content: $iconFont-tableColumnAddBefore;
				position: relative;
			}
			& .ql-tableColumnAddAfter:after {
				font-family: 'iconFont' !important;
				content: $iconFont-tableColumnAddAfter;
				position: relative;
			}
			& .ql-tableColumnDelete:after {
				font-family: 'iconFont' !important;
				content: $iconFont-tableColumnDelete;
				position: relative;
			}

			// styles to fix toolbar's font-size picker
			.ql-picker {
				&.ql-size {
					.ql-picker-label,
					.ql-picker-item {
						&::before {
							content: attr(data-value) !important;
						}
					}
				}
			}
		}

		// modify a few things in toolbar if theme is BUBBLE (extends what is above, really)
		.ql-bubble .ql-toolbar {
			button {
				color: #fff;
			}

			& .ql-darkLight:after {
				top: 0;
			}
		}

		.ql-snow .ql-toolbar {
			button {
				color: #000;
			}

			& .ql-darkLight:after {
				top: 0;
			}
		}

		// editor container
		// reset LI styles in wysiwyg editor
		.ql-editor li[data-list='bullet'] > .ql-ui::before,
		.ql-editor li[data-list='ordered'] > .ql-ui::before {
			content: '';
		}
		.ql-editor li {
			padding-left: 0;
		}

		.ql-snow .ql-editor .ql-code-block-container {
			background-color: var(--color-grey800);
			color: var(--color-grey100);
			font-size: var(--text-tiny);
			font-family: monospace;
		}

		@include element('container') {
			border: 1px solid var(--color-grey700);
			background-color: var(--color-grey950);
			border-bottom-left-radius: var(--radius-big);
			border-bottom-right-radius: var(--radius-big);
			border-top: none;

			&.ql-bubble {
				border: 1px solid var(--color-grey700);
			}

			& .ql-editor {
				min-height: v-bind(minHeight);
				padding-bottom: 40px;
			}

			& .ql-hidden {
				display: none;
			}
		}

		@include element('aiHelper') {
			position: relative;
			margin-top: -60px;
			margin-right: 15px;
			float: right;
			border-radius: var(--radius-big);
			box-shadow: 0px 0px 30px 5px transparentize($color: $color-tertiary, $amount: 0.7);
			width: 50px;
			transition: all var(--time-transition);

			&:hover {
				cursor: pointer;
				transform: scale(1.1);
				box-shadow: 0px 0px 30px 5px transparentize($color: $color-tertiary, $amount: 0.4);
			}
		}
	}
</style>
