import * as Core from '@Core/index.js';

const DEFAULT_STORE = {
	data: {
		user: {},
		purchases: [],
		progress: {}
	},
	loaded: {
		user: false,
		purchases: false
	}
};

// STORE DEFINITIONS -------------------
/**
 * @exports Student_Store_User
 * @namespace Student_Store_User
 */
export const useUserStore = Core.Pinia.defineStore({
	id: 'user',

	state: () => ({
		...Core.Utils.cloneObject(DEFAULT_STORE)
	}),

	actions: {
		/**
		 * Check if user is logged in, and get the details into store
		 * @async
		 * @param {boolean} force the check again, even it was done?
		 * @memberof Student_Store_User
		 */
		async fetchUser(force) {
			if (!this.loaded.user || force) {
				const resetStore = Core.Utils.cloneObject(DEFAULT_STORE);
				this.data.user = { ...resetStore.data.user };
				this.loaded = { ...resetStore.loaded };
				//useProductStore().reset(); TODO this was probably needed for something: D haha

				const { body } = await Core.Api.get('student/user/');

				if (body.status === 200) {
					this.data.user = body.message;
					this.loaded.user = true;
				}
			}
		},

		/**
		 * Get purchases for the logged-in user, requires user to be logged in
		 * @async
		 * @param {boolean} force the check again, even it was done?
		 * @memberof Student_Store_User
		 */
		async fetchPurchases(force) {
			if (this.loaded.user && (!this.loaded.purchases || force)) {
				const { body } = await Core.Api.get('student/purchases/');

				if (body.status === 200) {
					this.data.purchases = body.message;
					this.loaded.purchases = true;
				}
			}
		},

		/**
		 * Logout the user
		 * @async
		 * @memberof Student_Store_User
		 * @returns {Promise<string>} status type from the API
		 */
		async signOut() {
			const { body } = await Core.Api.get('student/user-signout/');
			if (body.status === 200) {
				window.location.href = '/';
			}

			return body.type;
		},

		/**
		 * Reset the store to default values
		 * @memberof Student_Store_User
		 */
		reset() {
			const resetStore = Core.Utils.cloneObject(DEFAULT_STORE);
			this.data = { ...resetStore.data };
			this.loaded = { ...resetStore.loaded };
		}
	},

	getters: {
		/**
		 * Return details about current user
		 * @memberof Student_Store_User
		 * @param {object} state automatically passed in
		 * @returns {Array} data for design changes
		 */
		get: (state) => {
			return state.data.user;
		},

		/**
		 * Return details about current user
		 * @memberof Student_Store_User
		 * @param {object} state automatically passed in
		 * @returns {Array} data for design changes
		 */
		getPurchases: (state) => {
			return state.data.purchases;
		}
	}
});
