<template>
	<div class="AiHelper">
		<blueprint-popup
			ref="aiPopup"
			size="col-24 col-md-20 offset-md-2"
			header="AI Creator"
			:footer="language.string.cAiHelper.beta"
			:on-close="cleanData">
			<!-- TOO SHORT CONTENT  -->
			<div
				v-if="state.tooShort"
				class="row">
				<p class="AiHelper--limitExceeded">
					<span class="iconFont-warning Color-warning"></span><br />
					{{ language.string.cAiHelper.tooShort }}
				</p>

				<div class="col-24 Text-center">
					<button
						class="Btn Btn-secondary"
						@click="close">
						{{ language.string.cAiHelper.closeBtn }}
					</button>
				</div>
			</div>

			<!-- LIMIT EXCEEDED TOO LONG  -->
			<div
				v-else-if="state.limitExceeded"
				class="row">
				<p class="AiHelper--limitExceeded">
					<span class="iconFont-warning Color-danger"></span><br />
					<span
						v-html="
							language.string.cAiHelper.limitExceeded.replace(
								'%CONTENT_LIMIT%',
								CONTENT_LIMIT
							)
						"></span>
				</p>

				<div class="col-24 Text-center">
					<button
						class="Btn Btn-secondary"
						@click="close">
						{{ language.string.cAiHelper.closeBtn }}
					</button>
				</div>
			</div>

			<!-- DAILY LIMIT REACHED  -->
			<div v-else-if="state.usedRequests >= state.dailyLimit">
				<div class="col-12 offset-6 Text-center">
					<div class="Callout Callout-danger Text-center">
						<p
							v-html="
								language.string.cAiHelper.limitReached.replace(
									'%DAILYLIMIT%',
									state.dailyLimit
								)
							"></p>
					</div>
					<button
						class="Btn Btn-secondary"
						@click="close">
						{{ language.string.cAiHelper.closeBtn }}
					</button>
				</div>
			</div>

			<!-- MAIN CONTENT  -->
			<template v-else>
				<transition
					name="fade"
					mode="out-in">
					<div
						v-if="!state.results && !state.failedResponse"
						class="row align-items-center">
						<div class="col-2">
							<img src="@School/assets/ai-icon.png?url" />
						</div>
						<div class="col-14">
							<p>
								{{ language.string.cAiHelper.desc }}
							</p>
						</div>
						<div class="col-8">
							<div
								class="Callout"
								:class="
									state.usedRequests >= state.dailyLimit
										? 'Callout-danger'
										: 'Callout-info'
								">
								<p
									class="Text-center"
									v-html="
										language.string.cAiHelper.limitCount
											.replace('%USED%', state.usedRequests)
											.replace('%MAX%', state.dailyLimit)
									"></p>
							</div>
						</div>

						<blueprint-select
							id="mode"
							:ref="fields.mode"
							v-model:value="state.mode"
							class="col-12"
							:options="language.string.cAiHelper.modeOptions"
							:label="language.string.cAiHelper.modeLabel"
							required />

						<blueprint-select
							id="tone"
							:ref="fields.tone"
							v-model:value="state.tone"
							class="col-12"
							:options="language.string.cAiHelper.toneOptions"
							:label="language.string.cAiHelper.voiceLabel"
							required />

						<div class="col-24">
							<div class="AiHelper--prompt StandardHtml">
								<div v-html="state.prompt"></div>
							</div>
						</div>

						<div class="col-24 Text-center">
							<button
								class="Btn Btn-secondary Space-right"
								@click="close">
								{{ language.string.cAiHelper.cancelBtn }}
							</button>
							<button
								class="Btn"
								:class="state.submitting && 'Btn-loading'"
								@click="submitPrompt">
								{{ language.string.cAiHelper.getContentBtn }}
							</button>
						</div>
					</div>

					<div
						v-else-if="state.failedResponse"
						class="col-18 offset-3 Text-center">
						<div class="Callout Callout-warning Text-center">
							<p>
								{{ language.string.cAiHelper.errorChanges }}
							</p>
						</div>
						<button
							class="Btn Btn-secondary Space-right"
							@click="close">
							{{ language.string.cAiHelper.finishBtn }}
						</button>
					</div>

					<div
						v-else
						class="row">
						<div class="col-24">
							<div class="AiHelper--results StandardHtml">
								<div v-html="state.results"></div>
							</div>

							<div class="Text-center">
								<button
									class="Btn Btn-secondary Space-right"
									@click="state.results = ''">
									{{ language.string.cAiHelper.tryAgainBtn }}
								</button>
								<button
									class="Btn"
									@click="acceptContent">
									{{ language.string.cAiHelper.acceptBtn }}
								</button>
							</div>
						</div>

						<div
							v-if="!state.feedbackOpen"
							class="col-24 Text-center Text-link Space-top Space-bottom">
							<p @click="state.feedbackOpen = true">
								{{ language.string.cAiHelper.feedbackText }}
							</p>
						</div>

						<div
							v-else-if="state.feedbackSuccess"
							class="Text-h4 Color-success Text-center Space-topDouble">
							{{ language.string.cAiHelper.feedbackThanks }}
						</div>
						<div
							v-else
							class="col-24 AiHelper--feedbackBox">
							<div class="row">
								<blueprint-input
									id="feedback"
									ref="feedbackField"
									v-model:value="state.feedback"
									:max-length="1000"
									class="col-12 offset-3"
									:label="language.string.cAiHelper.feedbackLabel"
									required />

								<div class="col-3">
									<div
										class="Btn Btn-secondary Btn-formAligned"
										@click="submitFeedback">
										{{ language.string.cAiHelper.submitBtn }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</transition>
			</template>
		</blueprint-popup>
	</div>
</template>

<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';

	const CONTENT_LIMIT = 5000;

	const DEFAULT_STATE = {
		mode: 'correction',
		tone: 'formal',
		relatedContent: null,
		submitting: false,
		results: '',
		limitExceeded: false,
		tooShort: false,
		failedResponse: false,
		dailyLimit: 20,
		usedRequests: 0,
		feedback: '',
		feedbackOpen: false,
		feedbackSuccess: false
	};

	export default {
		name: 'AiHelper',

		//  -------------------- PROPS --------------------
		props: {
			/**
			 * property {string} [content] - The content to be processed by the AI
			 * @namespace School_AiHelper
			 * @property {string} [content] - The content to be processed by the AI
			 */
			content: {
				type: String,
				required: false,
				default: ''
			},

			/**
			 * property {string} project - The project to be processed by the AI
			 * @namespace School_AiHelper
			 * @property {string} project - The project to be processed by the AI
			 */
			project: {
				type: String,
				validation: (value) => ['product', 'comment'].includes(value),
				required: true
			}
		},

		emits: ['update'],

		//  -------------------- SETUP --------------------
		setup(props, context) {
			const language = useLanguageStore();
			const aiPopup = Core.Vue.ref();

			const state = Core.Vue.reactive({
				...DEFAULT_STATE,
				prompt: props.content
			});

			const fields = {
				mode: Core.Vue.ref(),
				tone: Core.Vue.ref(),
				prompt: Core.Vue.ref()
			};

			const feedbackField = Core.Vue.ref();

			// -------------------- WATCH --------------------
			// watch if prop content changes
			Core.Vue.watch(
				() => props.content,
				(value) => {
					state.prompt = value;
				}
			);

			// -------------------- METHODS --------------------
			/**
			 * Open the AI popup
			 * @param {string} content - The content to be processed by the AI
			 * @param {string} relatedContent - The related content to be processed by the AI (optional)
			 */
			async function open(content, relatedContent) {
				if (!content || content.length < 16) {
					state.tooShort = true;
				} else if (content.length > CONTENT_LIMIT) {
					state.limitExceeded = true;
				} else {
					state.prompt = content;
					state.relatedContent = relatedContent;
				}

				// get limit from the API
				const responseLimit = await Core.Api.get('school/ai-limit');
				state.usedRequests = responseLimit.body.message || 0;

				aiPopup.value.toggle();
			}

			/**
			 * Close the AI popup (and clean data)
			 */
			function close() {
				aiPopup.value.toggle();
				setTimeout(() => {
					cleanData();
				}, 300);
			}

			/**
			 * Submit the prompt to the AI and get the results
			 */
			async function submitPrompt() {
				state.submitting = true;

				if (Core.Utils.validateFields(fields)) {
					let response;

					// request for products (likely htm content)
					if (props.project === 'product') {
						response = await Core.Api.post('school/ai-product', {
							content: state.prompt,
							mode: state.mode,
							tone: state.tone
						});
					}

					if (props.project === 'comment') {
						response = await Core.Api.post('school/ai-comment', {
							content: state.prompt,
							relatedContent: state.relatedContent,
							mode: state.mode,
							tone: state.tone
						});
					}

					if (response.body.type === 'SUCCESS') {
						state.results = response.body.message;
					}

					if (response.body.type === 'CONFLICT') {
						state.failedResponse = true;
					}
				}
				state.submitting = false;
			}

			/**
			 * Accept the content and continue
			 */
			function cleanData() {
				for (const key in state) {
					state[key] = DEFAULT_STATE[key];
				}
			}

			/**
			 * Accept the content and continue
			 */
			function acceptContent() {
				aiPopup.value.toggle();
				context.emit('update', state.results);
				cleanData();
			}

			/**
			 * Submit feedback to the AI
			 */
			async function submitFeedback() {
				if (Core.Utils.validateFields([feedbackField])) {
					await Core.Api.post('school/ai-feedback', {
						content: state.feedback
					});

					state.feedback = '';
					state.feedbackSuccess = true;
				}
			}

			return {
				language,
				Core,
				aiPopup,
				state,
				fields,
				feedbackField,
				submitPrompt,
				cleanData,
				acceptContent,
				open,
				close,
				submitFeedback
			};
		}
	};
</script>

<style lang="scss">
	@include block('AiHelper') {
		@include element('prompt') {
			margin-top: var(--space-singe);
			border: 1px solid var(--color-grey500);
			border-radius: var(--radius-big);
			padding: var(--space-single);
			background-color: var(--color-grey950);
			user-select: none;
		}

		@include element('results') {
			margin-top: var(--space-singe);
			border: 1px solid var(--color-grey500);
			border-radius: var(--radius-big);
			padding: var(--space-single);
		}

		@include element('limitExceeded') {
			font-size: var(--text-h4);
			text-align: center;
			margin-top: var(--space-double);

			& .iconFont-warning {
				font-size: var(--text-h1);
			}
		}

		@include element('feedbackBox') {
			margin-top: var(--space-single);
			padding-top: var(--space-single);
			padding-bottom: var(--space-single);
			background-color: var(--color-stateInfoBg);

			& .BpForms--input {
				background-color: var(--color-greyStart);
			}
		}
	}
</style>
